import React, { useEffect, useContext } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Media from "react-media";

// Context
import WindowWidthContext from "../../components/context/window-width";
import StoreContext from "../../components/context/store";
import { FooterColor } from "../../components/context/footer-color";

// Components
import Layout from "../../components/account/Layout";
import { AccountNavigation } from "../../components/account/account-navigation";
import { MobileAccountNavigation } from "../../components/account/mobile-account-navigation";
import { AspectRatioImageContainer } from "../../components/containers/aspect-ratio-image-container";

// Effects
import { Fade } from "react-awesome-reveal";

import {
	Page,
	AccountContainer,
	ContentContainer,
	TwoColumnGrid,
} from "../../components/trade/trade-components";

import { tertiary } from "../../components/utils/colors";

// Queries
const CUSTOMER_INFO = gql`
	query($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			email
			firstName
			lastName
			tags
		}
	}
`;

const BuyingGuideSection = styled.div`
	margin: 0 0 70px 0;

	max-width: 770px;
`;

const ArticleContainer = styled.div`
	& .article-text {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		margin: 10px 0 0 0;
	}

	& p {
		margin: 0;
	}
`;

const Img = styled.img`
	opacity: ${(props) => props.imageOpacity};
	transition: 250ms opacity ease;
`;

const AccountBuyingGuides = ({ location, data }) => {
	const windowWidth = useContext(WindowWidthContext);
	const { customerAccessToken } = useContext(StoreContext);

	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	const prismicData = data;
	const allBuyingGuides = prismicData.allPrismicBuyingGuide.edges.map(
		(content, index) => (
			<div key={`journal_article_${index}`}>
				<a
					href={content.node.data.downloadable_pdf.url}
					target="_blank"
					rel="noreferrer"
					download
				>
					<Fade cascade triggerOnce>
						<ArticleContainer>
							<AspectRatioImageContainer image={null} padding={`66.667`}>
								{content.node.data.thumbnail.fluid !== null && (
									<Img
										srcSet={content.node.data.thumbnail.fluid.srcSetWebp}
										src={content.node.data.thumbnail.fluid.srcWebp}
										alt={content.node.data.thumbnail.alt}
										loading={`lazy`}
									/>
								)}
							</AspectRatioImageContainer>
							<div className="article-text">
								<p>{content.node.data.title.text}</p>
								<span>↓</span>
							</div>
						</ArticleContainer>
					</Fade>
				</a>
			</div>
		)
	);

	return (
		<Layout>
			{customerAccessToken !== null && (
				<Query
					query={CUSTOMER_INFO}
					variables={{
						customerAccessToken: customerAccessToken.accessToken,
					}}
				>
					{({ loading, error, data }) => {
						if (loading) {
							return (
								<Page>
									<Media
										queries={{
											medium: "(min-width: 1025px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1025,
										}}
										render={() => (
											<AccountNavigation position={`relative`} top={0} />
										)}
									/>
									<AccountContainer>
										<ContentContainer>
											<p className="margin-0">Fetching</p>
										</ContentContainer>
									</AccountContainer>
								</Page>
							);
						}
						if (error) {
							return (
								<Page>
									<Media
										queries={{
											medium: "(min-width: 1025px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1025,
										}}
										render={() => (
											<AccountNavigation position={`relative`} top={0} />
										)}
									/>
									<AccountContainer>
										<ContentContainer>
											<p className="margin-0">
												Error loading page. Please try refreshing.
											</p>
										</ContentContainer>
									</AccountContainer>
								</Page>
							);
						}

						return (
							<Page>
								<Helmet title={`Materials Library | Christian Watson`} />

								<Media
									queries={{
										medium: "(min-width: 1025px)",
									}}
									defaultMatches={{
										medium: windowWidth === 1025,
									}}
									render={() => (
										<AccountNavigation position={`relative`} top={0} />
									)}
								/>

								<Media
									queries={{
										medium: "(max-width: 1024px)",
									}}
									defaultMatches={{
										medium: windowWidth === 1024,
									}}
									render={() => (
										<MobileAccountNavigation position={`relative`} top={0} />
									)}
								/>

								<AccountContainer>
									<ContentContainer>
										<BuyingGuideSection>
											<div className="section">
												<div
													className="caps"
													dangerouslySetInnerHTML={{
														__html:
															prismicData.prismicBuyingGuides.data.title.html,
													}}
												/>

												<div
													className="text-column"
													dangerouslySetInnerHTML={{
														__html:
															prismicData.prismicBuyingGuides.data.text.html,
													}}
												/>
											</div>
											<TwoColumnGrid>{allBuyingGuides}</TwoColumnGrid>
										</BuyingGuideSection>
									</ContentContainer>
								</AccountContainer>
							</Page>
						);
					}}
				</Query>
			)}
		</Layout>
	);
};

export const query = graphql`
	{
		prismicBuyingGuides {
			data {
				title {
					html
				}
				text {
					html
				}
			}
		}
		allPrismicBuyingGuide(sort: { order: ASC, fields: data___title___text }) {
			edges {
				node {
					data {
						title {
							text
						}
						downloadable_pdf {
							url
						}
						thumbnail {
							alt
							dimensions {
								height
								width
							}
							fluid(imgixParams: { ar: "3:2", fit: "crop", crop: "entropy" }) {
								srcSetWebp
								srcWebp
								aspectRatio
							}
						}
					}
				}
			}
		}
	}
`;

export default AccountBuyingGuides;
